import {
    styled,
    Grid,
    Box,
    Typography,
  } from '@mui/material';
  import "./i18n";
  import { useTranslation } from "react-i18next";


const AboutTitleTextTypography = styled(Typography)(({ theme }) => ({ 
  fontSize: 40,
  fontWeight: 100,
  textTransform: 'uppercase',
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 24,
    textAlign: 'center',
    width: '100%',
  },
}));

const AboutDescTextTypography = styled(Typography)(({ theme }) => ({ 
  fontSize: 16,
  fontWeight: 300,
  [theme.breakpoints.down('sm')]: {
    fontSize: 13,
    textAlign: 'center',
    width: '100%',
  },
}));

const ImgLemonCircle = styled(Box)(({ theme, item }) => ({
  width: '100%', 
  height: 479, 
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url("Limoes-circle.svg")`,
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  paddingRight: 20,
  [theme.breakpoints.down('xl')]: {
    paddingRight: 0,
  },
  [theme.breakpoints.down('sm')]: {
    height: 250, 
  },
}));

const ImgLemonAbout = styled(Box)(({ theme, item }) => ({
  width: 92, 
  height: 92, 
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url("Limoes-about.png")`,
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  marginLeft: 20,
  [theme.breakpoints.down('sm')]: {
    height: 53, 
    width: 53
  },
}));



function AboutSection(){
  const { t } = useTranslation();
    return(
        <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        id="about">
          <Grid item xs={10}>
            <Box>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={0}>
                <Grid item lg={6} xs={0}></Grid>
                <Grid item lg={6} xs={12}>
                  <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  >
                    <Grid item>
                      <AboutTitleTextTypography>{t("about")}</AboutTitleTextTypography>
                    </Grid>
                    <Grid item>
                    <ImgLemonAbout />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <ImgLemonCircle />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <AboutDescTextTypography>{t("about.description")}</AboutDescTextTypography>
                  <AboutDescTextTypography>{t("about.description.a")}</AboutDescTextTypography>
                  <br />
                  <AboutDescTextTypography>{t("about.description.b")}</AboutDescTextTypography>
                  <br />
                  <AboutDescTextTypography>{t("about.description.c")}</AboutDescTextTypography>
                  <br />
                  <AboutDescTextTypography>{t("about.description.d")}</AboutDescTextTypography>
                  <br />
                  <AboutDescTextTypography>{t("about.description.e")}</AboutDescTextTypography>
                  <br />
                  <AboutDescTextTypography>{t("about.description.f")}</AboutDescTextTypography>
                </Grid>
                <Grid item lg={0} xs={0}>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
    );
}
export default AboutSection;